.registration-wrapper {
  margin-bottom: 150px;
  margin-top: 100px;

  .MuiAccordion-root::before {
    display: none !important;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
    background-color: rgb(250, 250, 250);
  }

  .MuiAccordionDetails-root {
    flex-direction: column !important;
  }

  .MuiAccordionSummary-content {
    align-items: center;
  }

  .expand-optional-button:focus {
    outline: none;
  }

  .input-infoicon-wrapper {
    display: flex;
    align-items: center;
  }

  .nrl-upload,
  .certificate-upload {
    margin-bottom: 1.2rem;
  }

  .nrl-upload {
    margin-top: 0.5rem;
  }

  .authorization-upload {
    margin-bottom: 3.3rem;
  }

  .authorization-optional-note {
    margin-left: 12px;
    margin-right: 14px;
    color: rgba(0, 0, 0, 0.54);

    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }

  .radio-choices span.MuiFormControlLabel-label {
    // font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.9rem;
  }

  .authorization-legend {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 1.2rem;
  }

  .registration-screen {
    width: 650px;
    margin: 0 auto;

    .paper-body {
      padding: 20px 40px 40px 40px;

      .continue-button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
      }

      .city-state-province-input {
        display: flex;
        // align-items: flex-start;

        .city-input {
          width: 70%;
        }

        .state-province-input {
          width: 30%;
          margin-left: 10px;
        }
      }

      .firstname-and-middle-initial {
        display: flex;
        align-items: flex-start;

        .input-infoicon-wrapper:first-child {
          // width: 83%;
          margin-right: 10px;
        }

        .first-name-wrapper {
          width: 83%;
        }

        .mi-wrapper {
          width: 17%;

          .input-infoicon-wrapper {
            width: 100%;
          }
        }
      }

      .secondary-firstname-and-middle-initial {
        display: flex;
        align-items: flex-start;

        .input-infoicon-wrapper:first-child {
          margin-right: 10px;
        }

        .secondary-mi-wrapper {
          width: 20%;

          .input-infoicon-wrapper {
            width: 100%;
          }
        }
      }

      .input-field {
        width: 100%;
        // margin-bottom: 1rem;
      }
    }

    .custom-file {
      margin-bottom: 0.7rem;
    }
  }

  // TODO change
  .registration-screen-select-role {
    margin: 0 auto;
    // padding: 20px;

    .paper-body {
      padding: 20px;
    }
  }
}

.power-user-option {
  display: flex;
  align-items: center;
  margin-bottom: 2.8rem;
}

p {
  margin-bottom: 0;
}
.error-mssg-input-fields-red {
  color: #dc3545;
}
.error-mssg-input-fields-regular {
  color: rgba(0, 0, 0, 0.54);
}

.error-mssg-input-fields {
  // color: #f44336;
  // color: rgba(0, 0, 0, 0.54);
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 4px;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.extraClass {
  font-size: 14px !important;
  pointer-events: auto !important;
  font-weight: 400;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.paper-header {
  background-color: #001871;
  color: white;
  padding: 15px;
  font-size: 1.3rem;
  font-weight: 600;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tooltip-icon-info {
  display: flex;
  margin-left: 10px;
  // width: 10%;

  svg {
    transition: all 0.2s ease-in;
  }

  svg:hover {
    color: rgb(52, 81, 141);
    transform: scale(1.4);
    cursor: pointer;
  }
}

.tooltip-icon-info-select-role {
  display: flex;
  margin-bottom: 8px;

  svg {
    transition: all 0.2s ease-in;
  }

  svg:hover {
    color: rgb(52, 81, 141);
    transform: scale(1.4);
    cursor: pointer;
  }
}

.suffix-last-name-wrapper {
  display: flex;

  .last-name-wrapper {
    width: 83%;
    .input-infoicon-wrapper:first-child {
      margin-right: 10px;
    }
  }

  .suffix-wrapper {
    width: 17%;

    // .input-infoicon-wrapper {
    //   width: 100%;
    // }
  }
}

.sec-suffix-last-name-wrapper {
  display: flex;

  .sec-last-name-wrapper {
    width: 80%;
    .input-infoicon-wrapper:first-child {
      margin-right: 10px;
    }
  }

  .sec-suffix-wrapper {
    width: 20%;
  }
}

.certificate-password-field {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  label {
    width: 40%;
    margin-right: 0.5rem;
  }

  // .MuiFormControl-root {
  //   width: 60%;
  // }
  .certificate-password-input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: s;

    .MuiFormControl-root {
      width: 100%;
    }

    svg {
      margin-left: 10px;
    }
  }
  margin-bottom: 15px;
}
.certificate-bottom-buttons {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#create-certificate-title h2 {
  font-weight: 600;
  font-size: 1.5rem;
}

#create-certificate-title {
  border-bottom: 1px solid lightgrey;
}

.create-certificate-desc {
  margin-bottom: 30px !important;
}

.certificate-password-field {
  .input-infoicon-wrapper {
    display: flex;
    align-items: center;
  }
}

.error-container {
  margin-top: 4px;
  margin-left: 14px;
  margin-right: 14px;

  p,
  ul {
    color: #c60000;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin: 0;
    font-size: 0.75rem;
  }
}

.email-id-no-error {
  margin-top: 4px;
  margin-left: 14px;
  margin-right: 14px;

  p,
  ul {
    color: rgba(0, 0, 0, 0.54);
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin: 0;
    font-size: 0.75rem;
  }
}
