.welcome-wrapper {
  margin-top: 130px;
  margin-bottom: 100px;

  .login-screen {
    width: 600px;
    margin: 0 auto;
    // padding: 80px;
    padding-bottom: 75px;
    padding-top: 75px;
    position: relative;
    h2 {
      text-align: center;
      margin-bottom: 2.2rem;
    }

    .user-id-input-wrapper {
      width: 100%;
      padding-left: 75px;
      padding-right: 75px;
      display: flex;
      align-items: center;

      .info-icon {
        font-size: 1.4rem;

        transition: color 0.3s ease-in;
      }
      .user-id-tooltip {
        display: flex;
        transform: translateX(-130%);
      }
    }
    .password-input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 75px;
      padding-right: 75px;

      // position: relative;
      .info-icon {
        // position: absolute;
        // top: 50%;
        // right: 14px;
        font-size: 1.4rem;
        // transform: translateY(-50%);
        transition: color 0.3s ease-in;
      }

      .password-input-tooltip {
        display: flex;
        transform: translateX(-130%);
      }
    }

    .info-icon:hover {
      color: rgb(67, 105, 155);
    }

    .login-avatar-wrapper {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translate(50%, -50%);
      background-color: white;

      width: 170px;
      height: 170px;
      border-radius: 50%;
      border-top: 1px solid lightgray;
      // box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
      .login-avatar {
        height: 7rem;
        width: 7rem;
        color: grey;
        position: absolute;
        top: 0;
        transform: translate(50%, 20%);
        right: 50%;
      }
    }

    .user-id-input {
      width: 100%;
      // margin-bottom: 0.7rem;
    }

    .password-input {
      width: 100%;
    }

    .submit-button {
      width: 40%;
      margin-bottom: 2.2rem;
    }

    .forgot-password-link {
      align-self: flex-end;
      padding-right: 75px;
      padding-left: 75px;
      margin-bottom: 2.2rem;
    }
    .forgot-user-id {
      align-self: flex-end;
      padding-right: 75px;
      padding-left: 75px;
      margin-bottom: 1rem;
    }

    .agree-terms {
      margin-bottom: 2.2rem;
      width: 100%;
      padding-left: 75px;
      padding-right: 75px;

      .checkbox-message {
        font-weight: 300;
        font-size: 14px;
      }
      legend {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.66;
      }
    }

    // .agree-rules-wrapper {
    //   //   margin-bottom: 2.2rem;
    //   .agree-rules-message {
    //     font-weight: 400;
    //     font-size: 13px;
    //   }
    // }

    .account-registration-restoration {
      width: 100%;
      display: flex;
      justify-content: center;
      //   border-top: 1px solid lightgrey;
    }
  }
}

.modal-view-rules {
  display: flex;
  align-items: center;
  justify-content: center;

  .paper-with-rules {
    border: 2px solid #000;
    padding: 32px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;

    width: 600px;

    ul {
      list-style-position: inside;
      margin-bottom: 1rem;
    }

    .modal-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;

      svg {
        cursor: pointer;
      }
    }

    .iagree-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.form-of-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.forgot-password-paper {
  margin: 0 auto;
  margin-top: 8rem;
  margin-bottom: 8rem;
  width: 570px;
  padding: 20px;
  text-align: center;

  h2 {
    margin-bottom: 10px;
    font-size: 2rem;
  }

  h3 {
    font-weight: 300;
    margin-bottom: 100px;
    color: gray;
    font-size: 1.5rem;
  }
}

.rules-list {
  li {
    margin-bottom: 0.4rem;
  }
}
