* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// *:focus {
//   outline: none;
// }

body {
  font-family: Montserrat;
  // font-family: "Times New Roman";
  // font-size: 1.3rem;
}

main {
  flex: 1 0 auto;
  background-color: #edeef0;
}

*:focus {
  outline: 0 !important;
}

p,
ul,
h1,
h2 {
  margin-bottom: 0;
}

.table {
  /* overflow: auto;
  display: block;
  table-layout: auto; */

  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* TODO fix this important keyword */
  table-layout: auto !important;
  /* border: 1px solid #ddd; */
}

.custom-row-class:hover {
  background-color: #add8e6;
  cursor: pointer;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

@import "./header";
@import "./footer";
@import "./welcome";
@import "./registration";
@import "./update-account";
@import "./validate-accounts";
@import "./form-footer";
@import "./submit";
@import "./menu";
@import "./view-account-status";
