.form-footer-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .form-footer-cancel-button {
    margin-right: 10px;
  }
}
