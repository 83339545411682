.validate-enable-account-wrapper {
  width: 70%;
  margin: 100px auto 0px;

  .MuiFormControl-root {
    width: 100%;
  }
  // .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlined {
  //   border-color: white;
  // }
  // .MuiOutlinedInput-notchedOutlined {
  //   border-color: white !important;
  // }

  .validation-user-table {
    table thead tr {
      // background-color: #373940;
      background-color: grey;

      th {
        color: white;
        font-size: 1.3rem;
      }
    }
  }
}
