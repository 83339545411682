.MuiBreadcrumbs-separator {
  color: lightgrey;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: white !important;
}

// .menu-power-wt-user-wrapper {
//   .MuiTreeView-root {
//     background-color: lightgrey;
//   }
//   .MuiTreeItem-group {
//     margin: 0;
//     padding-left: 17px;
//     // border-bottom: 1px solid grey;
//   }
// }
.third-dropdown-level-padding {
  .MuiTreeItem-content {
    padding-left: 32px !important;
  }
}

.always-closed-tab > div {
  background-color: #e0e3e6 !important;
}

.folder-menu > div {
  // background-color: #e0e3e6 !important;
  // border-right: 1px solid black !important;
  font-weight: 500 !important;
}

// .MuiTreeItem-content {
//   background-color: lightsalmon;
// }

.popover {
  background-color: #000;

  .arrow::after {
    border-right-color: black;
  }
}

.bs-tooltip-top {
  z-index: 2000 !important;
}
