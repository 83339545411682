.header-main {
  background-color: #001871;

  box-shadow: 0 0 10px black;

  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // .bottom-row {

  // }

  a {
    display: inline-block;
    margin-left: 30px;

    img {
      display: block;
    }
  }

  h1 {
    color: white;
    text-align: center;
    width: 100%;
    padding: 20px;
    font-weight: 300;
    font-size: 2rem;
  }

  .dropdown {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1430px) {
  .top-row h1 {
    display: none;
  }

  .bottom-row {
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 1430px) {
  .top-row h1 {
    display: block;
  }

  .bottom-row {
    display: none;
  }
}
