.submissions-status {
  .MuiStepIcon-completed {
    color: #6aa84f !important;
  }

  .MuiStepIcon-active {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .MuiStepLabel-labelContainer {
    span {
      font-weight: 600;
      font-size: 1.1rem;
    }
  }

  .MuiStepIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
  }

  .MuiPaper-root {
    border-radius: 8px;
  }
}
