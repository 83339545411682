@media only screen and (max-width: 1010px) {
  .submit-page-wrapper {
    width: 95%;
  }
}
@media only screen and (min-width: 1010px) {
  .submit-page-wrapper {
    width: 1000px;
  }
}

.submit-page-wrapper {
  .MuiTypography-h6 {
    font-weight: 600;
  }

  display: flex;
  flex-direction: column;

  margin: 0 auto;

  // .input-heading:last-child {
  //   margin-top: 1rem;
  // }

  .user-input-row-submit-page {
    margin-bottom: 1rem;
  }

  .user-input-row-submit-page:last-child {
    margin-bottom: 0rem;
  }

  .input-heading {
    font-weight: 800;
    font-size: 1.2rem;
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;

    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
  }
  .document-certificate-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // height: 36.8%;

    .document-certificate {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
    }
  }
}

.user-input-1-summary {
  margin-bottom: 15px;
  // padding: 20px;
}
.user-input-1-summary > div {
  padding: 20px;
}
.user-input-2-summary {
  // margin-bottom: 15px;
  margin-bottom: 15px;
  padding: 20px;
}
.user-confirm-submit {
  margin-bottom: 15px;
  // padding: 15px;

  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: flex-start;

  label {
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
  }

  div {
    // padding: 15px;
    padding: 10px 19px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
}

.user-captcha-container {
  padding: 20px;
  margin-bottom: 15px;
}

.user-confirm-submit,
.user-captcha-container,
.user-input-1-summary,
.user-input-2-summary,
.power-user-input-2-summary,
.submit-page-footer {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 8px;
}

.power-user-input-2-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 15px;
}

.user-input-row-submit-page {
  .form-group {
    margin-bottom: 0;
    width: 60%;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.first-input-margin {
  margin-top: 1.2rem;
}

.submit-page-footer {
  padding: 20px;
  display: flex;
  justify-content: space-between;

  .previous-button {
    margin-left: 20px;
  }
}

.submit-update-button {
  display: flex;
  justify-content: flex-end;
}
