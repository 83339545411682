.update-account-wrapper {
  // margin-top: 100px;
  // margin-bottom: 100px;
  .update-account-paper {
    // width: 950px;
    // margin: 0 auto;

    .MuiAccordion-root::before {
      display: none !important;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
      background-color: rgb(250, 250, 250);
    }

    .MuiAccordionDetails-root {
      flex-direction: column !important;
    }

    .MuiAccordionSummary-content {
      align-items: center;
    }

    .expand-optional-button:focus {
      outline: none;
    }

    .form-group {
      margin-bottom: 0;
    }

    .paper-header {
      background-color: #373940;
      color: white;
      padding: 15px;
      font-size: 1.3rem;
      font-weight: 600;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .paper-body {
      padding: 30px;

      .update-button {
        justify-self: flex-end;
      }

      .update-info {
        padding-left: 15px;
      }
    }
  }
}

.web-trader-option {
  display: flex;
  align-items: center;
}

.pu-update-company-accounts {
  .MuiTabs-indicator {
    border-radius: 10px;
    height: 5px;
  }
  .MuiTab-textColorPrimary {
    color: #3f51b5;
  }
}

.MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-colCellTitle {
  font-size: 1.2rem;
  font-weight: 700 !important;
}

.input-row-styles > label {
  width: 30%;
}

.input-row-styles > div {
  width: 70%;
}
