footer {
  background-color: #001871;
  flex-shrink: 0;
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 10px black;
  position: relative;

  .footer-links {
    width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    a {
      color: white !important;
    }
  }
}
